<template>
  <AppMainContainer center-class="offer">
    <template #center>
      <div class="d-flex">
        <h1 class="page-header">
          {{ $t('header.user_option.FAQ') }}
        </h1>
      </div>
      <div
        v-if="!loader && FAQCategories"
        class="faq"
      >
        <div
          v-for="category in FAQCategories"
          :key="category.id"
          class="faq__item"
        >
          <h2
            class="faq__category"
            @click="toggleQuestions(category.id)"
          >
            {{ category.title }}
          </h2>
          <template v-if="category.isShown && !category.loader">
            <div
              v-for="it in category.questions"
              :key="it.id"
              class="faq__question"
            >
              <div
                class="faq__question-title"
                @click="it.isAnswerShown = !it.isAnswerShown"
              >
                {{ it.question }}
              </div>
              <div
                v-if="it.isAnswerShown"
                class="faq__question-answer"
              >
                {{ it.answer }}
              </div>
            </div>
          </template>
          <AppLoader
            v-if="category.loader"
            class="my-3 mb-4"
          />
        </div>
      </div>
      <AppLoader
        v-else
        class="full-height"
      />
    </template>
  </AppMainContainer>
</template>

<script>
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import AppLoader from '@/components/AppLoader'

import { mapActions } from 'vuex'

export default {
  name: 'PageFAQ',
  components: {
    AppMainContainer,
    AppLoader
  },
  data: () => ({
    FAQCategories: null,
    loader: false,
  }),
  async created () {
    await this.getFAQ()
  },
  methods: {
    ...mapActions({
      fetchFAQCategories: 'faq/fetchFAQCategories',
      fetchFAQCategoryQuestions: 'faq/fetchFAQCategoryQuestions',
    }),
    async getFAQ () {
      this.loader = true
      try {
        const { data } = await this.fetchFAQCategories()
        this.FAQCategories = data.map((it) => ({
          ...it,
          questions: [],
          isShown: false,
          loader: false
        }))
      } finally {
        this.loader = false
      }
    },
    async toggleQuestions (id) {
      const category = this.FAQCategories.find((it) => it.id === id)
      category.isShown = !category.isShown

      if (!category.isShown) {
        category.questions.forEach((it) => {
          it.isAnswerShown = false
        })
      }

      if (category.questions.length) {
        return
      }

      category.loader = true
      try {
        const { data } = await this.fetchFAQCategoryQuestions({
          'category[id]': id
        })
        category.questions = data.map((it) => ({ ...it, isAnswerShown: false }))
      } finally {
        category.loader = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.faq
  &__category
    margin-bottom: 20px
    font-weight: 800
    font-size: 17px
    line-height: 21px
    cursor: pointer

  &__question-title
    margin-bottom: 20px
    padding-left: 20px
    font-style: italic
    font-weight: 600
    font-size: 14px
    line-height: 140%
    opacity: 0.6
    cursor: pointer

  &__question-answer
    padding-left: 40px
    margin-bottom: 20px
    font-weight: 600
    font-size: 14px


</style>
